import React from 'react';

import CompetencesContainer, {
  COMPETENCES,
  genererFilAriane,
} from './container';

const Responsabilite = () => {
  return (
    <CompetencesContainer currentSection={COMPETENCES.RESPONSABILITE}>
      <h1>Responsabilité</h1>

      {genererFilAriane('responsabilité')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="responsabilite"
          src="/img/competences_sprite.jpg"
          alt="Responsabilité"
        />
      </div>

      <p>
        Nos avocats gèrent une grande variété de réclamations pour{' '}
        <span className="accent">préjudice corporel</span>. Les{' '}
        <span className="accent2">lésions corporelles</span> ont souvent un
        impact à long terme sur la qualité de vie de nos clients et c’est
        pourquoi nous déployons tous nos efforts à la recherche du meilleur
        résultat pour l’<span className="accent2">indemnisation</span> de leur
        préjudice.
      </p>
    </CompetencesContainer>
  );
};

Responsabilite.propTypes = {};

export default Responsabilite;
