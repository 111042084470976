import React from 'react';

import { PAGES } from '../../utils/utils';
import Responsabilite from '../../components/fr/competences/responsabilite';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const ResponsabilitePage = () => (
  <Layout page={PAGES.COMPETENCES_RESPONSABILITE}>
    <SEO title="Responsabilité" />
    <Responsabilite />
  </Layout>
);

export default ResponsabilitePage;
