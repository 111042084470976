/** @typedef { 'séparation-divorce' | 'garde d\'enfant' | 'médiation' | 'responsabilité' | 'construction' | 'assurances' | 'immobilier' } Competence */

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

export const COMPETENCES = {
  SEPARATION_DIVORCE: 'separation-divorce',
  GARDE_ENFANT: 'garde-enfant',
  MEDIATION: 'mediation',
  RESPONSABILITE: 'responsabilite',
  CONSTRUCTION: 'construction',
  ASSURANCES: 'assurances',
  IMMOBILIER: 'immobilier',
};

/**
 * @param {Competence} competence
 */
export const genererFilAriane = (competence) => (
  <p className="fil">
    <Link to="/">accueil</Link>
    &nbsp;›&nbsp;
    <Link to="/competences">compétences</Link>
    &nbsp;›&nbsp;
    <span className="fil">{competence}</span>
  </p>
);

const genererMenu = (sectionActive) => {
  const sections = [
    [COMPETENCES.SEPARATION_DIVORCE, 'Séparation-Divorce'],
    [COMPETENCES.GARDE_ENFANT, "Garde d'enfant"],
    [COMPETENCES.MEDIATION, 'Médiation'],
    [COMPETENCES.RESPONSABILITE, 'Responsabilité'],
    [COMPETENCES.CONSTRUCTION, 'Construction'],
    [COMPETENCES.ASSURANCES, 'Assurances'],
    [COMPETENCES.IMMOBILIER, 'Immobilier'],
  ];

  return sections.map(([section, nom], i) => {
    if (sectionActive === section) {
      return (
        <li key={section} className="activeText">
          &nbsp;›&nbsp;&nbsp;&nbsp;{nom}
        </li>
      );
    } else {
      const href = `/competences/${section}`;
      const lastClass = i === sections.length - 1 ? 'lastChild' : '';

      return (
        <Link key={section} to={href}>
          <li className={lastClass}>
            &nbsp;›&nbsp;&nbsp;&nbsp;
            <span>{nom}</span>
          </li>
        </Link>
      );
    }
  });
};

const CompetencesContainer = ({ currentSection, children }) => {
  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Compétences</h1>
      </div>

      <div id="info">
        {/* menu navigation gauche */}

        <div id="navCompetencesPlus">
          <ul>
            <li className="firstChild">Compétences</li>
            {genererMenu(currentSection)}
          </ul>
        </div>

        <div id="texteCompPlus">{children}</div>
      </div>
    </>
  );
};

CompetencesContainer.propTypes = {
  children: PropTypes.node.isRequired,
  currentSection: PropTypes.oneOf(Object.values(COMPETENCES)).isRequired,
};

export default CompetencesContainer;
